@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&family=Prompt:wght@400;600;700&family=Titillium+Web:wght@400;700&display=swap");

body {
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
  font-size: 16px !important;
  scroll-behavior: smooth !important;
}

button,
a,
p,
li,
span {
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
}

h3 {
  line-height: normal !important;
}

a {
  text-decoration: none !important;
}

img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.lang-button a {
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
  font-size: 14px !important;
  color: #001736;
  padding: 5px 10px;
  font-weight: 700;
}
.lang-button a:hover,
.lang-button a.active {
  background-color: #0179cc !important;
  color: #ffff !important;
  padding: 5px 10px;
  border-radius: 5px;
}

.lang-button hr {
  border-color: #001736 !important;
  opacity: 1 !important;
  align-self: center !important;
  height: 20px !important;
}

.menu {
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
  background-color: #001736;
  color: #fff;
}

.menu a button {
  font-size: 16px;
  padding: 10px 40px;
  border-radius: 0;
  text-transform: capitalize;
}

.menu a:first-child button {
  padding: 10px 15px;
}

.menu button:first-child svg {
  font-size: 28px;
}

.menu button:hover,
.menu button:focus {
  background-color: #0179cc;
}

.footer {
  background: url(../images/bg-footer.png) no-repeat !important;
  padding: 100px 0 !important;
  background-size: cover !important;
  color: #fff !important;
}

.footer a {
  color: #fff !important;
}

.footer a:hover {
  color: #0179cc !important;
}

.MuiListItemIcon-root {
  min-width: 0 !important;
  margin-right: 20px !important;
}

.copyright {
  background-color: #0179cc;
  padding: 10px 0;
}

.copyright p {
  color: #fff;
  font-size: 12px;
}

.text-banner_inner {
  color: #fff;
}

.text-banner_inner h1 {
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

.text-banner_inner p {
  font-weight: normal;
}

.text-banner_inner button {
  background-color: #fff !important;
  color: #001736 !important;
  padding: 8px 40px;
  margin-top: 20px;
  font-weight: 700;
}

.text-banner_inner button:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.content {
  padding: 100px 0;
}

.t-blue {
  color: #0179cc;
}

.card-product {
  transition: transform 750ms;
  will-change: transform;
}

.card-product .card {
  border: none;
}

.box-product_main .card-img-overlay {
  bottom: 20px !important;
  left: 20px !important;
  right: 20px !important;
  top: auto !important;
  padding: 0;
}

.box-product_main .card-title {
  color: #001736 !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}

.box-more button {
  background-color: #0179cc !important;
  color: #fff;
  font-size: 28px;
  font-weight: bolder;
  line-height: 1;
  width: 45px;
  height: 40px;
  padding: 0 0 5px 0;
  min-width: 40px;
  border-radius: 0;
  vertical-align: text-top;
}

.box-more {
  position: absolute;
  opacity: 0;
}

.box-more_left {
  top: 20px;
  left: 25px;
}

.box-more_right {
  top: 20px;
  right: 25px;
}

.card-product:hover .box-more,
.card-product:focus .box-more {
  opacity: 1;
}

.btn-link button {
  background-color: #001736 !important;
  color: #fff;
  padding: 8px 40px !important;
}

.btn-link button:hover {
  background-color: #0179cc !important;
}

.step_main {
  background: url(../images/bg-step.png) no-repeat !important;
  background-size: cover !important;
}

.step_text li {
  list-style: none;
  font-size: 14px;
  margin-bottom: 5px;
}

.subheading_inner {
  position: relative;
  padding-bottom: 15px;
}

.subheading_inner::before {
  position: absolute;
  bottom: -5px;
  height: 5px;
  width: 95px;
  left: 44%;
  content: "";
  background: #0179cc;
}

.subheading_inner::after {
  position: absolute;
  bottom: -5px;
  height: 5px;
  width: 35px;
  right: 45%;
  content: "";
  background: #0179cc;
}

.card-step {
  border: none;
  background: url(../images/bg-inactive_step.png) no-repeat #fff !important;
  background-size: cover !important;
  background-position: center !important;
}

.card-step .card-body {
  padding: 30px 20px;
}

.card-step img {
  display: block;
  margin: 0 auto;
  padding: 30px;
  width: 60% !important;
  height: 270px !important;
}

.step_content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #000;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.step_link button {
  padding: 10px !important;
  min-width: 30px;
  min-height: 30px;
  border-radius: 0;
  background-color: #001736;
  color: #fff;
}

.card-step .card-title {
  color: #0179cc;
}

a:hover .card-step {
  background: url(../images/bg-active_step.png) no-repeat #fff !important;
  background-size: cover !important;
  background-position: center !important;
}

a:hover .step_link button {
  background-color: #0179cc;
  color: #fff;
}

.link-normal {
  color: #001736;
  font-size: 16px;
}

.link-normal:hover {
  color: #0179cc;
}

.heading-news_inner {
  padding-bottom: 15px;
  border-bottom: 3px solid #001736 !important;
  margin-bottom: 25px;
}

.news-list_inner {
  background-color: #eee !important;
  padding: 15px 25px;
  color: #000 !important;
  font-size: 14px !important;
}

.news-list_inner:hover {
  background-color: rgba(1, 121, 204, 0.2) !important;
}

.sub-contact-inner {
  position: relative;
}

.sub-contact-inner a {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.bg-darkblue {
  background-color: #001736 !important;
  color: #fff;
  border-radius: 0;
  border: none;
}

.bg-blue {
  background-color: #0179cc !important;
  color: #fff;
  border-radius: 0;
  border: none;
}

.card-img-overlay h1 {
  transition: transform 250ms;
}

.card-subcontact:hover .card-img-overlay h1 {
  transform: translateY(-10px);
}

.coverMain {
  background: url(../images/bg-cover.png) no-repeat !important;
  background-size: cover !important;
  min-height: 400px !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  align-items: end;
  display: flex !important;
  padding: 100px 0 50px;
}

.breadcrumbs,
.breadcrumbs a {
  color: #fff !important;
}

.breadcrumbs {
  margin: 20px 0 0 50px !important;
}

.process_menu-btn a {
  background-color: #f1f1f1;
  color: #000;
  padding: 12px 30px;
  border-radius: 5px;
}

.process_menu-btn a:hover,
.process_menu-btn a.active {
  background-color: #0179cc;
  color: #fff;
  padding: 12px 30px;
  border-radius: 5px;
}

.sub-inner_headding {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.sub-inner_headding h5 {
  border-left: 5px solid #0179cc;
  padding-left: 20px;
  font-weight: 600;
}

.card-product h5 {
  color: #0179cc !important;
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-bottom: 15px !important;
}

.card-product p {
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
  font-size: 14px !important;
  margin-bottom: 0 !important;
  line-height: 1.7 !important;
}

.MuiGrid-item {
  padding-top: 10px !important;
}

.card-product {
  background: url(../images/shape_inactive.png) no-repeat !important;
  background-size: contain !important;
  background-position: bottom right !important;
  border-radius: 10px !important;
}

a:hover .card-product {
  background: url(../images/shape_active.png) no-repeat !important;
  background-size: contain !important;
  background-position: bottom right !important;
}

.product_inner-text {
  padding: 20px 30px;
}

.card-product,
.card-product_subpage {
  position: relative;
}

.card-product_subpage .content-overlay {
  background: rgba(1, 121, 204, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  z-index: 1;
}

a:hover .card-product_subpage .content-overlay {
  opacity: 1;
}

.card-product_subpage .card-img {
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  z-index: 1;
}

a:hover .card-product_subpage .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h1 {
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.fadeIn-right {
  left: 80%;
}

.modal-modal-title {
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #0179cc;
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
}

.close-btn {
  background-color: #0179cc !important;
  color: #fff !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  padding: 4px !important;
  z-index: 1;
}

.link-in_process a {
  scroll-behavior: smooth !important;
}

.card-list-instru {
  background-color: #fff !important;
  box-shadow: none !important;
  padding: 0;
  border-radius: 0 !important;
}

.list-instru_inner {
  border-radius: 5px !important;
}

.list-instru-text {
  padding: 20px 0 0 0 !important;
}

.list-instru-text h5 {
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
  font-weight: 600;
  border-left: 5px solid #0179cc;
  padding-left: 20px;
  margin: 0;
  font-size: 18px;
  line-height: 1.2;
}

.list-instru-text ul {
  list-style: none !important;
  margin-top: 10px;
  padding-left: 25px;
  font-size: 14px;
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
}

.table-instru table td,
.table-instru table th {
  vertical-align: middle;
  font-size: 14px !important;
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
}

.search-news input {
  padding: 12px 20px !important;
  font-size: 14px !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #0179cc !important;
}

.subject-news {
  padding-bottom: 10px !important;
  margin-bottom: 25px !important;
  border-bottom: 1px solid #eee !important;
}

.subject-news h5 {
  color: #0179cc;
  margin-bottom: 15px;
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
}

.subject-news p {
  color: #001736;
  font-size: 14px;
}

.box-about_text {
  background: url(../images/bg-about.png) no-repeat !important;
  background-size: cover !important;
  padding: 40px !important;
}

.box-about_text h3 {
  color: #0179cc !important;
  font-weight: 600 !important;
  margin-bottom: 20px !important;
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
}

.box-about_text span {
  line-height: 2 !important;
  font-size: 16px !important;
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
}

.list-about,
.affiliates_box-inner {
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(241, 241, 241, 1) !important;
}

.list-last,
.affiliates_box-inner .list-about {
  border: none !important;
}

.list-about h6 {
  font-size: 16px !important;
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
  font-weight: 600;
}

.list-about p {
  margin-bottom: 15px;
  line-height: 1.5;
}

.list-about a {
  color: #000 !important;
  font-size: 16px !important;
  margin-bottom: 15px !important;
  display: inline-block !important;
}

.list-about a:hover {
  color: #0179cc !important;
}

.list-about a.t-blue {
  color: #0179cc !important;
  margin-bottom: 0 !important;
}

.list-about a.t-blue:hover {
  color: #0179cc !important;
  text-decoration: underline !important;
}

.form-contact {
  background: url(../images/bg-pattern.png) no-repeat !important;
  background-size: cover !important;
  background-position: 50% 50% !important;
  padding: 30px 0;
}

.affiliates_box {
  background: url(../images/bg-affiliates.png) no-repeat !important;
  background-size: cover !important;
}

.map-img {
  position: relative !important;
}

.btn-popover {
  position: absolute !important;
  background-color: #001736 !important;
  min-width: 20px !important;
  width: 30px !important;
  height: 30px !important;
  padding: 0 0 3px 0 !important;
  border-radius: 50% !important;
  font-size: 18px !important;
  line-height: 1 !important;
  vertical-align: middle !important;
  margin-bottom: 10px !important;
  color: #fff !important;
}

.btn-popover_1 {
  bottom: 15%;
  right: 21%;
}

.btn-popover_2 {
  bottom: 40%;
  right: 11%;
}

.btn-popover:hover {
  background-color: #0179cc !important;
}

.map-img .MuiMenu-root {
  top: 0 !important;
}

.popover-box {
  background-color: #001736 !important;
  display: block;
  margin-top: 0;
  padding: 0;
  padding: 20px 30px;
  color: #fff;
  position: absolute;
}

.box-thai {
  bottom: 15%;
  right: 25%;
}

.box-japan {
  top: 12%;
  right: 11%;
}

.popover-box h6 {
  font-family: "Titillium Web", "M PLUS Rounded 1c", "Prompt", sans-serif !important;
  margin-bottom: 10px !important;
}

.popover-box ul {
  margin-left: 0 !important;
  padding: 0 0 0 15px !important;
  margin: 0 !important;
}

.popover-box ul li a {
  color: #fff !important;
  font-size: 14px !important;
  margin: 0 0 5px 10px !important;
  display: inline-block !important;
}

.popover-box ul li a:hover {
  text-decoration: underline !important;
}

.box-thai {
  padding: 15px 20px !important;
}

.box-thai h6 {
  margin: 0 !important;
  font-weight: 600;
  letter-spacing: 1.2px;
  font-size: 16px;
}

.MuiTextField-root {
  margin: 0;
}

.btn-send button {
  background-color: #0179cc !important;
  color: #fff !important;
  padding: 8px 40px !important;
}

.btn-send button:hover {
  background-color: #001736 !important;
}

.floating-btn {
  position: relative !important;
}

.floating {
  padding: 10px !important;
  box-shadow: 0px 4px 20px rgb(170 180 190 / 30%) !important;
}

.floating:hover {
  background-color: #005694 !important;
}

.totop {
  box-shadow: 0px 4px 20px rgb(170 180 190 / 30%) !important;
  background-color: #c2e0ff !important;
}

.totop:hover {
  background-color: #99ccf3 !important;
}

/* */

.mb_menu {
  background-color: #001736 !important;
  color: #fff !important;
  margin: auto 0 auto 5px !important;
  border-radius: 0 !important;
}

#lang-menu ul {
  width: 100px !important;
  margin-top: 10px !important;
  padding: 0 !important;
}

#lang-menu ul li {
  background-color: #0179cc !important;
  display: block !important;
  text-align: center;
  border-bottom: 1px solid rgba(59, 163, 237, 1) !important;
  padding: 10px !important;
}

#lang-menu ul li:hover {
  background-color: #001736 !important;
}

#lang-menu ul li:last-child {
  border: none !important;
}

#lang-menu ul li a {
  color: #fff !important;
  font-size: 14px !important;
}

.mo-logo img {
  display: block !important;
  text-align: center !important;
}

.mb-lang {
  padding: 0 !important;
  height: 43px !important;
  vertical-align: middle !important;
  margin: auto 5px auto 10px !important;
}

.drawer a li {
  color: #fff !important;
  border-bottom: 1px solid #42425e !important;
  display: block !important;
  text-align: center !important;
  padding: 15px !important;
  font-size: 14px !important;
}

.drawer a:last-child li {
  border: none !important;
}

.footer-contact p {
  font-size: 14px !important;
}

.gdpr {
  position: relative;
}

.gdpr-box {
  position: fixed;
  bottom: 0;
  background-color: #000;
  padding: 10px;
  z-index: 9999;
}

.gdpr-box p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
}

.gdpr-box p a {
  padding: 0 5px;
  color: #0179cc;
}

.gdpr-box p a:hover {
  color: #95edf9;
}

.link-gdpr {
  background-color: #0179cc;
  color: #fff;
  padding: 8px 40px;
  border-radius: 5px;
  font-size: 16px;
  width: 150px;
  margin-left: 50px;
  text-align: center;
}

.link-gdpr:hover {
  background-color: #000;
}

.text-box a {
  display: block;
  margin-bottom: 10px;
}

.text-box a:hover {
  color: #001736;
}

@media screen and (max-width: 1366px) {
  .box-japan {
    top: 14% !important;
    right: 15% !important;
  }
}

@media screen and (max-width: 1180px) {
  .menu a button {
    padding: 10px 20px !important;
  }
  .card-img-overlay h1 {
    font-size: 24px !important;
  }
  .process_menu-btn a {
    display: inline-block !important;
    text-align: center !important;
  }
  .box-japan {
    top: -5% !important;
    right: 11% !important;
  }
}

@media screen and (max-width: 820px) {
  .mo-logo img {
    width: 100% !important;
  }
  .footer-contact {
    text-align: left !important;
  }
  .process_menu-btn,
  .process_menu-btn a {
    width: 100% !important;
  }
  .news-list_inner {
    text-align: left !important;
    justify-content: flex-start !important;
    width: 100% !important;
    align-items: flex-start !important;
  }
  .news-list_inner hr {
    display: none !important;
  }
  .card-step img {
    width: 100% !important;
    height: 230px !important;
  }
  .box-japan {
    top: -15% !important;
    right: 17% !important;
  }
  .box-thai {
    bottom: 15% !important;
    right: 27% !important;
  }
  .search-news {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .toolbar {
    padding: 10px !important;
  }
  .mo-logo {
    width: 100% !important;
  }

  .mo-logo img {
    width: 100% !important;
    padding-right: 15px !important;
  }
  .searchinput {
    background-color: #001736 !important;
    color: #fff !important;
  }
  .searchinput div {
    width: 100% !important;
    justify-content: start !important;
  }
  .searchinput input {
    color: #fff !important;
    width: 100% !important;
  }
  .searchinput svg {
    color: #fff !important;
  }
  .t-s {
    text-align: left !important;
  }
  .footer {
    padding: 50px 20px !important;
  }
  .coverMain {
    min-height: auto !important;
    padding: 50px 20px !important;
  }
  .coverMain h3 {
    font-size: 24px !important;
  }
  .breadcrumbs {
    margin: 10px 0 0 40px !important;
  }
  .modal-inner {
    width: 90% !important;
  }
  .btn-popover_1 {
    bottom: 15% !important;
    right: 35% !important;
  }
  .btn-popover_2 {
    bottom: 40% !important;
    right: 15% !important;
  }
  .popover-box {
    padding: 10px 20px !important;
  }
  .popover-box h6 {
    font-size: 16px !important;
  }
  .popover-box ul li a {
    font-size: 12px !important;
  }
  .box-japan {
    top: -25% !important;
    right: 15% !important;
  }
  .popover-box.box-thai h6 {
    font-size: 12px !important;
  }
  .box-thai {
    bottom: 35% !important;
    right: 35% !important;
  }
  .table-instru table th {
    width: 25%;
  }
  .sub-contact-inner a {
    width: 100%;
  }
  .gdpr-box {
    flex-direction: column;
  }
  .gdpr-box .ms-auto {
    width: 100%;
    text-align: center;
  }
  .news-01 colgroup col{
    width: 100% !important;
  }
  .news-01 thead, tr {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto !important;
  }
  table.news-01{
    width: 100% !important;
  }
}
